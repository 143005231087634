import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { StoresIds, Region } from '~/types'
import { useHttpClient } from '~/composables/useHttpClient'
import globalFlag from '@/assets/icons/countries/global.png'
import countryList from '@/assets/all.json'

export const useHeaderMainStore = defineStore(
    StoresIds.HEADER_STORE,
    () => {
        const isLoadingCountries = ref(false)
        const isHeaderFixed = ref(false)
        const isShowShareButton = ref(false)
        const countries = ref<Region[]>([])
        const menuList = ref()
        const selectedRegion = ref<Region>({
            id: 507,
            slug: 'global',
            icon: '/_nuxt/assets/icons/countries/global.png',
            countryLanguages: [
                {
                    id: 2855,
                    name: 'Global',
                    languageCharCode: 'EN',
                    localLanguage: 'English',
                    order: 1
                },
                {
                    id: 2806,
                    name: 'Глобал',
                    languageCharCode: 'RU',
                    localLanguage: 'Русский',
                    order: 2
                }
            ],
            name: 'Global',
            translations: [
                {
                    id: 2855,
                    name: 'Global',
                    languageCharCode: 'EN',
                    localLanguage: 'English',
                    order: 1
                },
                {
                    id: 2806,
                    name: 'Глобал',
                    languageCharCode: 'RU',
                    localLanguage: 'Русский',
                    order: 2
                }
            ],
            countryCode: 'G1',
            selected: true
        })
        const industries = ref()
        const specializations = ref()
        const stories = ref([])

        const route = useRoute()
        const slug = computed(
            () =>
                route.query.region ||
                selectedRegion.value?.slug ||
                'global'
        )
        const getCountryIconUrl = (countryCode: string) => {
            const cc = countryList
                .find((c: any) => c.countryCode === countryCode)
                ?.countryCode?.toLowerCase()
            return `https://storage.googleapis.com/gratanet/web_files/flag/${cc}.webp`
        }

        // const router = useRouter()
        // const { locale } = useI18n()
        const getHeaderData = async (
            isMainPage = true,
            selectedRegionSlug: string = 'global'
        ) => {
            isLoadingCountries.value = true
            if (!slug.value) {
                console.error('SLUG IS ', slug.value)
            }

            try {
                const response = await useHttpClient(
                    '/content/header',
                    {
                        query: {
                            slug: selectedRegionSlug,
                            main: isMainPage
                        }
                    }
                )
                const rawCountries = response?.data?.value?.countries
                if (response.data?.value?.stories) {
                    stories.value = response.data.value.stories
                }
                countries.value = rawCountries?.map(country => {
                    const item = {
                        id: country.id,
                        slug: country.slug,
                        icon:
                            country.slug === 'global'
                                ? globalFlag
                                : getCountryIconUrl(
                                      country.countryCode
                                  ),
                        countryLanguages: country.translations,
                        name: country.countryName,
                        translations: country.translations,
                        countryCode: country.countryCode,
                        countryType: country.countryType
                    }

                    return item
                })

                menuList.value = response?.data?.value?.menus?.sort(
                    (a, b) => a.order - b.order
                )
                // selectedRegion.value = countries.value?.find(
                //     c => c.slug === selectedRegionSlug
                // ) as Region

                if (countries.value?.length) {
                    countries.value = countries.value
                        .map(item => ({
                            ...item,
                            selected:
                                selectedRegion.value?.slug ===
                                item.slug
                        }))
                        .sort((a, b) => {
                            if (a.slug === 'global') return -1
                            if (b.slug === 'global') return 1

                            // Then prioritize selected items
                            // if (a.selected && !b.selected) return -1
                            // if (!a.selected && b.selected) return 1

                            // Finally, sort by name
                            const nameA = a.name.toUpperCase()
                            const nameB = b.name.toUpperCase()

                            if (nameA < nameB) {
                                return -1
                            }
                            if (nameA > nameB) {
                                return 1
                            }
                            return 0
                        })
                    const route = useRoute()

                    isLoadingCountries.value = false
                    selectedRegion.value = countries.value?.find(
                        c =>
                            c.slug === route.query.region ||
                            c.slug === selectedRegionSlug
                    ) as Region
                }
            } catch (e) {
                console.error('ERROR WHEN GET MAIN PAGE DATA', e)
                isLoadingCountries.value = false
            }
        }

        return {
            countries,
            menuList,
            selectedRegion,
            industries,
            specializations,
            getHeaderData,
            isHeaderFixed,
            isShowShareButton,
            stories,
            isLoadingCountries
        }
    }
)
